<template>
  <div id="app" :style="{ background: `url(${url})`, backgroundRepeat: 'no-repeat', backgroundSize: '100% 100%' }">
    <span class="placeholder"></span>

    <div class="container">
      <p class="contentInfo">
        <span
          style="font-size: 28px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #4D4E50;
            line-height: 49px;"
          >美好生活</span
        ><span
          style="font-size: 28px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #FF3E7D;
          line-height: 49px;margin-left: 9px;">
          尽在Win生活</span
        >
      </p>
      <div class="loginShare-button">
        <div class="loginShare-button-item">
          <van-button size="small" round color="#FE5080" @click="goxiazai">去下载</van-button>
        </div>

        <div class="loginShare-button-item">
          <van-button size="small" round color="#FE5080" @click="enterApp">去看看</van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import url from './win.png'
export default {
  data () {
    return {
      url
    }
  },

  methods: {
    enterApp () {
      if (navigator.userAgent.indexOf('QQTheme') != -1) {
        Toast.fail('请在复制链接在默认浏览器中打开')
        return
      }
      if (navigator.userAgent.indexOf('MicroMessenger') != -1) {
        Toast.fail('请点击右上角按钮在在浏览器中打开')
        return
      }
      const type = this.$route.query.type
      const types = this.$route.query.types
      const liveId = this.$route.query.liveId
      const userId = this.$route.query.userId
      if (type == 'live') {
        if (navigator.userAgent.includes("Mac")) {
          window.location.href = `winLife://live?liveId=${liveId}&userId=${userId}`
        } else {
          window.location.href = `winlife://live?liveId=${liveId}&userId=${userId}`
        }
      } else if (types == '7') {
        if (navigator.userAgent.includes("Mac")) {
          window.location.href = 'winLife://slyderAdventures'
        } else {
          window.location.href = 'winlife://slyderadventures'
        }
      } else if (types == '8') {
        if (navigator.userAgent.includes("Mac")) {
          window.location.href = 'winLife://shakeIfOff'
        } else {
          window.location.href = 'winlife://shakeifoff'
        }
      }
    },

    goxiazai () {
      if (navigator.userAgent.indexOf('QQTheme') != -1) {
        Toast.fail('请在复制链接在默认浏览器中打开')
        return
      }

      if (navigator.userAgent.indexOf('MicroMessenger') != -1) {
        Toast.fail('请点击右上角按钮在在浏览器中打开')
        return
      }

      if (navigator.userAgent.includes("Mac")) {
        window.location = "https://apps.apple.com/cn/app/id1638693950"
      } else {
        window.location.href = "https://www.pgyer.com/eOTh"
      }
    }
  }
}
</script>

<style lang="less" scoped>
#app {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}
.placeholder {
  height: 25%;
}
.container {
  background: #fff;
  flex: 1;
  border-radius: 20px 20px 0 0;
  padding: 100px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.img {
  width: 93px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 11;
  margin-top: 60px;
}
.contentInfo {
  flex: 1;
  display: flex;
  justify-content: center;
  span {
    margin: 0 2px;
  }
}
.loginShare-button {
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &-item {
    flex: 1;
    text-align: center;
    .van-button {
      width: 80%;
    }
  }
}
.van-nav-bar {
  background-color: transparent;
}
/deep/.van-nav-bar__left .van-icon-arrow-left {
  color: white;
}
/deep/.van-nav-bar--fixed {
  background-color: transparent;
}
/deep/ .van-nav-bar__text {
  color: black;
}
</style>
